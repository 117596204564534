<template>
  <section
    :class="
      'back-cover' +
      (IsActive ? ' active' : ' deactive') +
      (DisplayNone ? ' display-none' : '')
    "
  >
    <div class="add-pay-form">
      <div class="side-form-title">
        RÚT SÉC NHẬP QUỸ
        <span class="icon-wrapper" @click="SetActive(false)">
          <b-icon icon="close"></b-icon>
        </span>
      </div>
      <div class="form-content">
        <ValidationObserver ref="observer">
          <div class="mt-3">
            <ValidationProvider
              :rules="{ required: true }"
              name="Nhân viên thực hiện"
              v-slot="{ errors }"
            >
              <b-field
                custom-class="is-small"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <template #label>
                  Nhân viên thực hiện <span class="has-text-danger">*</span>
                </template>
                <b-input
                  v-model="SearchData.UserLog"
                  disabled
                  class="custom-ip"
                />
              </b-field>
            </ValidationProvider>
          </div>
          <div class="mt-3 columns mb-0">
            <div class="column is-6 pb-0 pt-0">
              <ValidationProvider
                :rules="{ required: true }"
                name="Quyển Séc"
                v-slot="{ errors }"
              >
                <b-field
                  custom-class="is-small"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                >
                  <template #label>
                    Quyển Séc <span class="has-text-danger">*</span>
                  </template>
                  <b-input v-model="SubmitData.Sec" class="custom-ip" />
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-6 pb-0 pt-0">
              <ValidationProvider
                :rules="{ required: true }"
                name="Số Series"
                v-slot="{ errors }"
              >
                <b-field
                  custom-class="is-small"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                >
                  <template #label>
                    Số Series <span class="has-text-danger">*</span>
                  </template>
                  <b-input v-model="SubmitData.Series" class="custom-ip" />
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="mt-3">
            <ValidationProvider
              :rules="{ required: true }"
              name="Số tiền"
              v-slot="{ errors }"
            >
              <b-field
                custom-class="is-small"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <template #label>
                  Số tiền <span class="has-text-danger">*</span>
                </template>
                <currency-input
                  class="input input-text-right custom-ip custom-ip-curency"
                  :precision="0"
                  expanded
                  v-model="SubmitData.Total"
                />
              </b-field>
            </ValidationProvider>
          </div>
          <div class="mt-3 columns mb-0">
            <div class="column is-6 pb-0 pt-0">
              <ValidationProvider
                :rules="{ required: true }"
                name="Ngân hàng rút"
                v-slot="{ errors }"
              >
                <b-field
                  label="Ngân hàng rút"
                  custom-class="is-small"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                >
                  <template #label>
                    Ngân hàng rút
                    <span class="has-text-danger">*</span>
                  </template>
                  <v-select
                    placeholder="Chọn"
                    class="style-chooser-custom custom-ip"
                    label="Value"
                    v-model="SubmitData.BankCode"
                    :options="listBank"
                    :reduce="(option) => option.Code"
                    @input="ChangeBankTransfer"
                  >
                    <div slot="no-options">Không có kết quả phù hợp</div>
                  </v-select>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-6 pb-0 pt-0">
              <b-field custom-class="is-small">
                <template #label> Số dư quỹ ngân hàng rút </template>
                <currency-input
                  class="input custom-ip custom-ip-curency"
                  :precision="0"
                  expanded
                  v-model="SearchData.TotalFund"
                  disabled
                />
              </b-field>
            </div>
          </div>
          <div class="mt-3">
            <ValidationProvider
              :rules="{ required: true }"
              name="Nội dung"
              v-slot="{ errors }"
            >
              <b-field
                custom-class="is-small"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <template #label>
                  Nội dung <span class="has-text-danger">*</span>
                </template>
                <b-input
                  v-model="SubmitData.Note"
                  placeholder="Nội dung"
                  type="textarea"
                  class="custom-ip"
                >
                </b-input>
              </b-field>
            </ValidationProvider>
          </div>
        </ValidationObserver>
        <div class="mt-3">
          <b-field>
            <template #label> Ảnh chứng từ </template>
            <div class="upload-field custom-bt-upload">
              <b-upload
                @input="uploadFiles"
                v-model="files"
                multiple
                accept=".png, .jpg, .jpeg"
              >
                <span
                  class="upload-file-title"
                  :class="{ 'upload-file-title-active': true }"
                  >Chọn ảnh chứng từ</span
                >
                <div class="button-upload">
                  <img src="@/assets/img/upload.svg" />
                </div>
              </b-upload>
            </div>
          </b-field>
        </div>
        <div class="mt-3">
          <div class="upload-photos">
            <FileItem
              v-for="(item, index) in SubmitData.ListImages"
              :key="index"
              :file="item"
              :idx="index"
              @deleteFile="deleteFile"
              :IsShow="true"
            />
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <b-button type="is-link" @click.prevent="onSave()" :disabled="isDisableSave">RÚT SÉC NHẬP QUỸ</b-button>
      </div>
    </div>
  </section>
</template>
<script>
import Enum from "@/helpers/Enum.vue";
//import UserService from "@/modules/user/services/UserService.vue";
import FileItem from "@/modules/fundtransfers/components/FileItem.vue";
import UploadAWS from "@/helpers/UploadAWS.vue";
import moment from "moment";
// import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import store from "@/store";
import ToastHelper from "@/helpers/OpenToast.vue";
// import FundService from "@/modules/common/services/FundService.vue";
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import WithdrawSecServices from "@/modules/withdrawsecs/services/WithdrawSecServices.vue";
import UploadStatic from "@/helpers/UploadStatic.vue";
var amzUrl = process.env.VUE_APP_STATIC_DOMAIN;

export default {
  components: {
    FileItem,
  },
  props: ["listBank"],
  data() {
    return {
      SearchData: {
        TotalFund: 0,
        UserLog: "",
      },
      isDisableSave:false,
      TypeTransactions: [],
      IsActive: false,
      DisplayNone: true,
      IsLoading: false,
      Enum: Enum,
      TypeTransactionCode: null,
      filUser: {
        FullName: null,
        EmployeeCode: null,
        ShopCode: "7702",
      },
      files: [],
      filterBalance: {
        staffCode: null,
        officeCode: null,
        currencyCode: "VND",
        paymentType: 2,
        receiveMethod: null,
      },
      SubmitData: {
        Code: null,
        Total: 0,
        Note: null,
        Sec: null,
        Series: null,
        BankCode: null,
        BankName: null,
        ShopCode: null,
        ShopName: null,
        ShortName: null,
        Balance: null,
        StaffCode: null,
        CreatedBy: null,
        CreatedFullName: null,
        ShopAddress: null,
        ListImages: [],
      },
      listShop: [],
    };
  },
  mounted() {},
  methods: {
    SetActive(value) {
      let _self = this;
      // _self.isDisableSave=false;
      _self.IsActive = value;
      _self.DisplayNone = !value;
      _self.ResetData();
    },
    async uploadFiles() {
      var _self = this;
      _self.isLoading = true;
      for (let i = 0; i < _self.files.length; i++) {
        let item = _self.files[i];
        let now = moment();
        let folder = "Images/WithdrawSec";
        let pathRoot = `${now.format("YYYY")}/${now.format("MM")}/${folder}`;
        let fileName = `${now.format("YYYYMMDD")}_${now.format(
          "HHmmss"
        )}_${now.format("X")}_${Math.floor(Math.random() * 0x10000).toString(
          16
        )}`;
        let pathFull = `${pathRoot}/${fileName}.${item.name.slice(
          item.name.lastIndexOf(".") + 1
        )}`;

        await UploadStatic.PutFileS3(
          pathFull,
          item,
          fileName,
        );

        // if (result) {
          let file = {
            Id: 0,
            FileName: fileName,
            Path: amzUrl + pathFull,
          };
          _self.SubmitData.ListImages.push(file);
        // }
      }
      _self.files = [];
    },
    deleteFile(file) {
      var _self = this;
      _self.$buefy.dialog.confirm({
        title: "Xóa ảnh",
        message: Enum.MESSAGE.ConfirmDeleteImage + file.Name + "</b>",
        confirmText: "Đồng ý",
        cancelText: "Hủy",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          var currentItem = _self.SubmitData.ListImages.filter(
            (x) => x.Path == file.Path
          )[0];
          var index = _self.SubmitData.ListImages.indexOf(currentItem);
          if (index > -1) {
            await UploadAWS.deleteFile(file.Path);
            _self.SubmitData.ListImages.splice(index, 1);
          }
        },
      });
    },
    async onSave() {
      let _self = this;
      let isValid = await _self.validateData();
      if (!isValid) return;
      if (
        parseFloat(_self.SubmitData.Total) >
        parseFloat(_self.SearchData.TotalFund)
      ) {
        ToastHelper.OpenToast(Enum.MESSAGE.MoneyOver, "is-warning");
        return;
      }
      if (_self.SubmitData.Total === 0) {
        ToastHelper.OpenToast(Enum.MESSAGE.MoneyZero, "is-warning");
        return;
      }

      if (_self.isDisableSave) return;
      _self.isDisableSave=true;

      setTimeout(async () => {
        try {
          _self.$parent.isLoading = true;
          // _self.isDisableSave=true;
          var result = await WithdrawSecServices.Create_PostWithdrawSec(
            _self.SubmitData 
          );
          if (result) {
            ToastHelper.OpenToast(Enum.MESSAGE.Ok_Insert + "!", "is-success");
            _self.SetActive(false);
          } else {
            ToastHelper.OpenToast(Enum.MESSAGE.Update_Error + "!", "is-danger");
          }
          _self.$emit("AddSuccess");
          _self.$parent.isLoading = false;
          _self.isDisableSave=false;
        }
        catch (e) { console.log(e)}
      }, 500);
    },
    async ResetData() {
      var _self = this;
      _self.SearchData = {
        TotalFund: 0,
        UserLog: "",
      };
      _self.SubmitData = {
        Code: null,
        Total: 0,
        Note: null,
        Sec: null,
        Series: null,
        BankCode: null,
        BankName: null,
        ShopCode: null,
        ShopName: null,
        ShortName: null,
        Balance: null,
        StaffCode: null,
        CreatedBy: null,
        CreatedFullName: null,
        ListImages: [],
        BusinessOfficeCode:null
      };
      _self.filterBalance = {
        staffCode: null,
        officeCode: null,
        currencyCode: "VND",
        paymentType: 2,
        receiveMethod: "",
      };
      _self.SetDefaultData();
      //_self.GetBalanceByUser();
    },
    async ChangeBankTransfer(value) {
      var _self = this;
      if (value == null || value == "") {
        _self.SearchData.TotalFund = 0;
        return;
      }
      let selectedTypeTransaction = _self.listBank.filter(
        (x) => x.Code == value
      )[0];
      _self.filterBalance.receiveMethod = selectedTypeTransaction.Value;
      _self.SubmitData.BankName = selectedTypeTransaction.Value;

      _self.GetBalanceByUser();
    },
    async SetDefaultData() {
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      let res = store.state.user.Profile;
      var _self = this;
      var result = await FundTransferService.Get_ListPGDNew();
      _self.listShop = result.Data;
      let shopSelet = _self.listShop.filter((x) => x.Code == shopSelected.Code);
      if (shopSelet != null) shopSelected = shopSelet[0];
      _self.SubmitData.ShopCode = shopSelected.Code;
      _self.SubmitData.ShopName = shopSelected.Name;
      _self.SubmitData.ShopAddress = shopSelected.Address;
      _self.SubmitData.ShortName = shopSelected.ShortName;
      _self.filterBalance.officeCode = shopSelected.BusinessOfficeCode;
      _self.SubmitData.BusinessOfficeCode=shopSelected.BusinessOfficeCode;
      _self.filterBalance.staffCode = res.EmployeeCode;
      _self.SubmitData.StaffCode = res.EmployeeCode;
      _self.SubmitData.CreatedFullName = res.FullName;
      _self.SubmitData.CreatedBy = res.UserName;
      _self.SearchData.UserLog = res.FullName + " - " + res.EmployeeCode;
    },
    async GetBalanceByUser() {
      var _self = this;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      _self.filterBalance.officeCode = shopSelected.BusinessOfficeCode;
      if (shopSelected.BusinessOfficeCode == Enum.OFFICE.HO) {
        _self.filterBalance.officeCode = Enum.OFFICE.HO;
      }
      _self.SubmitData.receiveMethod =_self.filterBalance.receiveMethod;
      var result = await FundTransferService.Post_GetFundBalance(
        _self.filterBalance
      );
      _self.SearchData.TotalFund = 0;
      if (result != null && result.balance > 0) {
        _self.SearchData.TotalFund = parseFloat(result.balance);
      }
      _self.SubmitData.Balance = _self.SearchData.TotalFund;
    },
    async validateData() {
      return await this.$refs.observer.validate().then(async (result) => {
        if (!result) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.observer.errors)
              .map(([key, value]) => ({ key, value }))
              .filter((error) => error["value"].length);
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 100);

          return false;
        }

        return true;
      });
    },
  },
};
</script>
<style scoped lang="scss">
</style>
