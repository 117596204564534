
 <template>
  <div class="modal-card modal-work-flow-content">
    <header class="modal-card-head">
      <div class="columns custom-card-head" style="width: 100%">
        <span class="hearder-liquidation">Hủy nhận quỹ</span>
      </div>
    </header>
    <section class="modal-card-body">
      <p class="fund-warning">Bạn đang thực hiện hủy lệnh rút séc nhập quỹ:</p>
      <p class="fund-info">
        {{ item.CreatedFullName !== null ? item.CreatedFullName : "" }}
        -
        {{ item.ShopName !== null ? item.ShopName : "" }}
      </p>
      <span>Số tiền: </span
      ><span class="money-fund">{{ formatCurrency(item.Total) }}</span>
      <p class="mar-bot-5">Lý do huỷ</p>
      <b-input v-model="SubmitData.Note" type="textarea" class="custom-ip"></b-input>
    </section>
    <footer class="modal-card-foot">
      <div class="center-button">
        <div class="footer-border-content-validate">
          <b-button type="is-text" @click="$emit('close')"> Đóng </b-button>
          <b-button class="btn-next" @click="cancelFundReceive"> Hủy </b-button>
        </div>
      </div>
    </footer>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>
<script>
import WithdrawSecServices from "@/modules/withdrawsecs/services/WithdrawSecServices.vue";
import store from "@/store";
import ToastHelper from "@/helpers/OpenToast.vue";
import Enum from "@/helpers/Enum.vue";
export default {
  props: ["item"],
  data() {
    return {
      isLoading: false,
      SubmitData: {
        Id: null,
        Note: null,
        UpdateUserName: null,
        UpdateFullName: null,
      },
      isDisableSave:false
    };
  },
  mounted() {
    let _self = this;
    let res = store.state.user.Profile;
    _self.isDisableSave=false;
    _self.SubmitData.UpdateUserName = res.UserName;
    _self.SubmitData.UpdateFullName = res.FullName;
    _self.SubmitData.Id = _self.item.Id;
  },
  methods: {
    async cancelFundReceive() {
      let _self = this;
      _self.isLoading=true;
      let result = await WithdrawSecServices.Update_PostWithdrawSec(_self.SubmitData);
      _self.isDisableSave=true;
      if(result)
      {
        ToastHelper.OpenToast(Enum.MESSAGE.Update_Success, "is-success");
      }
      else{
        ToastHelper.OpenToast(Enum.MESSAGE.Update_Error, "is-danger");
        _self.isDisableSave=false;
      }
      _self.isLoading=false;
      _self.$emit('close');
      _self.$emit('loadData');
    },
  },
};
</script>
