<template>
  <div class="modal-content">
    <div class="modal-main">
      <div class="mt-3">
        <b-field>
          <div class="upload-field">
            <b-upload
              @input="uploadFiles"
              v-model="files"
              multiple
              accept=".png, .jpg, .jpeg"
            >
              <span
                class="upload-file-title"
                :class="{ 'upload-file-title-active': true }"
                >Ảnh đính kèm</span
              >
              <div class="button-upload">
                <img src="@/assets/img/upload.svg" />
              </div>
            </b-upload>
          </div>
        </b-field>
      </div>
      <div class="mt-3">
        <div class="upload-photos">
           <viewer class="diplay-flex" :images="ListImage">
          <FileItem
            v-for="(item, index) in ListImage"
            :key="index"
            :file="item"
            :idx="index"
            @deleteFile="deleteFile"
            :IsShow="true"
          />
           </viewer>
        </div>
      </div>
      <div style="padding-top: 5px; text-align: center">
        <b-button label="Đóng" type="is-text" @click="onClose()"> </b-button>
        <b-button
          label="Lưu ảnh"
          @click="onSave()"
          type="is-info"
          class="custom-button"
        >
        </b-button>
      </div>
    </div>
        <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>
<script>
import FileItem from "@/modules/fundtransfers/components/FileItem.vue";
import WithdrawSecServices from "@/modules/withdrawsecs/services/WithdrawSecServices.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
import Enum from "@/helpers/Enum.vue";
export default {
  components: {
    FileItem,
  },
  props: ["ListImage", "SMData"],
  data() {
    return {
      files: [],
      isLoading:false
    };
  },
  mounted() {
    this.files = null;
  },
  methods: {
    async deleteFile(items) {
      this.$emit("deleteFile", items);
    },
    async uploadFiles() {
      let _self = this;
      _self.$emit("uploadFile", _self.files);
      _self.files=null;
    },
    async onSave() {
      let _self = this;
      _self.isLoading=true;
      let result = await WithdrawSecServices.Update_PostUpdateImage(_self.SMData);
      if (result) {
        ToastHelper.OpenToast(Enum.MESSAGE.Update_Success, "is-success");
      } else {
        ToastHelper.OpenToast(Enum.MESSAGE.Update_Error, "is-danger");
      }
      _self.isLoading=false;
      _self.onClose();
    },
    async onClose() {
      let _self = this;
      _self.$emit("close");
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss">

</style>
