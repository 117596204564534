<template>
  <div>
    <div class="order">
      <div>{{ offset + idx + 1 }}</div>
    </div>
    <div class="col">
      <div class="columns">
        <template v-if="data.Status.Code !== $getConst('FUNDSTATUS').Cancelled">
          <div class="column is-1 text-align">
            {{ ConvertToDateTime.ConvertUnixToDatetime(data.UpdateDate) }}
          </div>
          <div class="column is-2 custom-w-12">
            {{ data.CreatedFullName }}<br />
            {{ data.StaffCode }}
          </div>
          <div class="column is-1 text-align money-tranfer">
            +{{ formatCurrency(data.Total) }}
          </div>
          <div class="column is-2">
            {{ data.TypeMethod }}
          </div>
          <div class="column is-1">
            {{ data.Sec }}
          </div>
          <div class="column is-2" style="color: #7a8798">{{ data.Note }}</div>
        </template>
        <template v-else>
          <div class="column is-1 del text-align">
            {{ ConvertToDateTime.ConvertUnixToDatetime(data.UpdateDate) }}
          </div>
          <div class="column is-2 del custom-w-12">
            {{ data.CreatedFullName }}<br />
            {{ data.StaffCode }}
          </div>
          <div class="column is-1 del text-align">
            +{{ formatCurrency(data.Total) }}
          </div>
          <div class="column is-2 del">
            {{ data.TypeMethod }}
          </div>
          <div class="column is-1 del">
            {{ data.Sec }}
          </div>
          <div class="column is-2 del" style="color: #7a8798">
            {{ data.Note }}
          </div>
        </template>

        <div class="column is-1 custom-w-10">
          <span :style="{ color: data.Status.Color }">
            <b-icon icon="checkbox-blank-circle" size="is-small"></b-icon>
            <b class="pawn-status">{{ data.Status.Name }}</b>
            <p
              v-if="data.Status.Code === $getConst('FUNDSTATUS').Cancelled"
              class="note-cancel"
            >
              <b-tooltip
                :label="data.ReasonCancel"
                type="is-white"
                position="is-right"
              >
                Lý do
              </b-tooltip>
            </p>
          </span>
        </div>

        <!-- <div class="column is-1 custom-w-10">
          <span :style="{ color: data.Status.Color }">
            <b-icon icon="checkbox-blank-circle" size="is-small"></b-icon>
            <b class="pawn-status">{{ data.Status.Name }}</b>
          </span>
        </div> -->
        <div
          class="column is-2 btn-action"
          v-if="
            data.Status.Code === $getConst('FUNDSTATUS').WaitConfirm &&
            data.StaffCode == store.state.user.Profile.EmployeeCode
          "
        >
          <div class="button-action-receive">
            <b-button
              class="button-cancel"
              outlined
              :disabled="isDisableButton"
              @click.prevent="onMenuClick(1)"
              v-if="
                checkAccessRole(
                  $getConst('MODULE').Mifos,
                  $getConst('ACTION').WithdrawSec.Delete
                )
              "
            >
              Hủy
            </b-button>

            <b-button
              class="button-confirm"
              outlined
              :disabled="isDisableButton"
              @click.prevent="onMenuClick(2)"
              v-if="
                checkAccessRole(
                  $getConst('MODULE').Mifos,
                  $getConst('ACTION').WithdrawSec.Approve
                )
              "
            >
              Xác nhận
            </b-button>
          </div>
        </div>
        <div
          v-if="
            data.Status.Code === $getConst('FUNDSTATUS').Complete &&
            data.StaffCode == store.state.user.Profile.EmployeeCode
          "
          class="column is-2 cursor-pointer alight-center btn-action"
        >
          <div class="menu-dot">
            <b-dropdown position="is-top-left" append-to-body aria-role="menu">
              <template #trigger>
                <a role="button">
                  <span>
                    <img src="@/assets/img/circledoted.svg" />
                  </span>
                </a>
              </template>
              <span class="dropdown-menu-title">chức năng</span>
              <hr class="dropdown-divider" aria-role="menuitem" />
              <b-dropdown-item
                value="edit_pawn"
                aria-role="menuitem"
                class="menu-item"
                @click="onMenuClick(3)"
              >
                <span>Bổ sung chứng từ</span>
                <b-icon
                  icon="folder-multiple-image"
                  size="is-small"
                  class="pad-left-20"
                ></b-icon>
              </b-dropdown-item>
              <b-dropdown-item
                value="transaction_pawn"
                aria-role="menuitem"
                class="menu-item"
                @click="printTranfer()"
                  v-if="
                  checkAccessRole(
                    $getConst('MODULE').WithdrawSec,
                    $getConst('ACTION').WithdrawSec.Print
                  )
                "
              >
                <span>In phiếu</span>
                <b-icon
                  icon="cloud-print-outline"
                  size="is-small"
                  class="pad-left-20"
                ></b-icon>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div id="print" style="display: none">
      <WithdrawSecPrint :item="data" code="PC/HS/08/745" />
    </div>
  </div>
</template>
<script>
import ConvertToDateTime from "@/helpers/ConvertDatetime.vue";
import WithdrawSecPrint from "@/modules/withdrawsecs/components/WithdrawSecPrint.vue";
import store from "@/store";
export default {
  props: ["data", "idx", "offset", "Employee"],
  components: { WithdrawSecPrint },
  data() {
    return {
      ConvertToDateTime: ConvertToDateTime,
      store: store,
      isDisableButton: false
    };
  },
  mounted() {},
  methods: {
    async printTranfer() {
      var _self = this;
      // _self.item = await Service.getCollectPOSId(_self.contact.Id);
      _self.$nextTick(function () {
        _self.$htmlToPaper("print");
      });
    },
    onMenuClick(action) {
      let _self = this;
      switch (action) {
        case 1: // Hủy
          if (_self.isDisableButton) return;
          _self.isDisableButton = true;

          setTimeout(() => {
            try {
              _self.CancelWithdrawSec();
            }
            catch (e) { console.log(e)}
            _self.isDisableButton = false;
          }, 500);
          break;
        case 2: // Xác nhận
          if (_self.isDisableButton) return;
          _self.isDisableButton = true;
          setTimeout(() => {
            try {
              _self.ConfirmWithdrawSec();
            }
            catch (e) { console.log(e)}
            _self.isDisableButton = false;
          }, 500);
          break;
        case 3: // Chứng năng
          _self.AdditionalAttacment();
          break;
      }
    },
    async ConfirmWithdrawSec() {
      let _self = this;
      _self.$emit("ConfirmWithdrawSec", _self.data);
    },
    async CancelWithdrawSec() {
      let _self = this;
      _self.$emit("CancelWithdrawSec", _self.data);
    },
    async AdditionalAttacment() {
      let _self = this;
      _self.$emit("Additional", _self.data.Id);
    },
  },
};
</script>
<style scoped>
.del {
  text-decoration: line-through;
}
.money-tranfer {
  color: #34a263;
  font-weight: bold;
}
</style>
