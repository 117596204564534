 <template>
  <div class="modal-card modal-work-flow-content">
    <header class="modal-card-head">
      <div class="columns custom-card-head" style="width: 100%">
        <span class="hearder-liquidation">Xác nhận giao dịch</span>
      </div>
    </header>
    <section class="modal-card-body">
      <p>Bạn đang thực hiện lệnh xác nhận rút séc nhập quỹ:</p>
      <p class="fund-info">
        {{ item.CreatedFullName !== null ? item.CreatedFullName : "" }} -
        {{ item.ShopName !== null ? item.ShopName : "" }}
      </p>
      <span>Số tiền: </span
      ><span class="money-fund">{{ formatCurrency(item.Total) }}</span>
    </section>
    <footer class="modal-card-foot">
      <div class="center-button">
        <div class="footer-border-content-validate">
          <b-button type="is-text" @click="$emit('close')"> Đóng </b-button>
          <b-button
            class="btn-confirm"
            @click.prevent="confirmFundReceive"
            :disabled="isDisableSave"
          >
            Xác nhận
          </b-button>
        </div>
      </div>
    </footer>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>
<script>
import ToastHelper from "@/helpers/OpenToast.vue";
import Enum from "@/helpers/Enum.vue";
import FundService from "@/modules/common/services/FundService.vue";
import store from "@/store";
export default {
  props: ["item"],
  data() {
    return {
      isLoading: false,
      isDisableSave: false,
      request: {
        amount: null,
        note: null,
        referenceSourceId: "4", // Mã kênh thực hiện (0: Mifos 1: Los 2: App 3: Bao hiem )
        referenceTransactionType: "73",
        paymentType: "1", // Tiền mặt
        transationType: "2", // 1: Thu 2: Chi
        office: null,
        createdBy:null,
        detail: {
          officeCodeTransaction: null,
          receiveMethodTransaction: null, //ngược
          receiveMethod: null, //xuôi
          receiptCode: null,
          referenceReceiptCode: null,
          businessType: Enum.BUSINESS_TYPE.CQ,
          transferType: null,
          transactionId: null,
          referenceTransactionId: null,
          // transactionId: "0",
          // referenceTransactionId: "0",
        },
      },
    };
  },
  methods: {
    async confirmFundReceive() {
      let _self = this;
      if (_self.isDisableSave) return;
      _self.isDisableSave = true;

      setTimeout(async () => {
        try {
          _self.isLoading = true;
        
          let result = await _self.StepOne();
          let resul2 = await _self.StepTwo();
          if (result && resul2) {
            ToastHelper.OpenToast(Enum.MESSAGE.Update_Success, "is-success");
            _self.$emit("close");
          } else {
            ToastHelper.OpenToast(Enum.MESSAGE.Update_Error, "is-danger");
          }
          _self.isLoading = false;
          _self.$emit("loadData");
        }
        catch (e) {console.log(e);}
        _self.isDisableSave=false;
      }, 500)
    },
    async StepOne() {
      let _self = this;
      _self.request.amount = _self.item.Total.toString();
      _self.request.note = _self.item.Note;
      _self.request.paymentType = Enum.PAYMENT_TYPE.Bank;
      _self.request.transationType = Enum.TRANSACTIONTYPEMIFOS.Tran;
      _self.request.referenceTransactionType = Enum.TRANTYPEMIFOS.Withdraw.Tran;
      _self.request.office = _self.item.BusinessOfficeCode;
      _self.request.detail.officeCodeTransaction =
        _self.item.BusinessOfficeCode;
      _self.request.detail.receiveMethod = _self.item.BankName;
      _self.request.detail.referenceReceiptCode = _self.item.CodeRef;
      _self.request.detail.receiptCode = _self.item.Code;
      _self.request.detail.transferType = "NH > TM";
      _self.request.detail.transactionId = _self.item.TranCode;
      _self.request.detail.referenceTransactionId = _self.item.ReceiveCode;
      _self.request.createdBy=store.state.user.Profile.EmployeeCode;
      _self.request.userOfficeCode=_self.item.BusinessOfficeCode;
      _self.request.staffCode=store.state.user.Profile.EmployeeCode;
      let result = await FundService.Post_CreateTransactionFund(
        _self.item.Id,
        2,
        store.state.user.Profile.FullName,
        _self.request
      );
      return result;
    },
    async StepTwo() {
      let _self = this;
      _self.request.amount = _self.item.Total.toString();
      _self.request.note = _self.item.Note;
      _self.request.paymentType = Enum.PAYMENT_TYPE.Cash;
      _self.request.transationType = Enum.TRANSACTIONTYPEMIFOS.Re;
      _self.request.referenceTransactionType = Enum.TRANTYPEMIFOS.Withdraw.Re;
      _self.request.office = _self.item.BusinessOfficeCode;
      _self.request.detail.officeCodeTransaction =
        _self.item.BusinessOfficeCode;
      _self.request.detail.receiveMethodTransaction = _self.item.BankName;
      _self.request.detail.receiptCode = _self.item.CodeRef;
      _self.request.detail.referenceReceiptCode = _self.item.Code;
      _self.request.detail.transferType = "NH > TM";
      _self.request.detail.transactionId = _self.item.ReceiveCode;
      _self.request.detail.referenceTransactionId = _self.item.TranCode;
      _self.request.createdBy=store.state.user.Profile.EmployeeCode;
      _self.request.userOfficeCode=_self.item.BusinessOfficeCode;
      _self.request.staffCode=store.state.user.Profile.EmployeeCode;
      let result = await FundService.Post_CreateTransactionFund(
        _self.item.Id,
        2,
        store.state.user.Profile.FullName,
        _self.request
      );
      return result;
    },
  },
};
</script>
