<template>
  <section>
    <div class="main-content">
      <div class="pawn-detail-content">
        <div class="header-bar-asset-config">
          <img class="back-btn" src="@/assets/img/prev.svg" alt />
          <div class="title-asset-config">RÚT SÉC NHẬP QUỸ</div>
          <b-button
            class="button add-pay-op"
            @click="ShowAddForm()"
            v-if="
              checkAccessRole(
                $getConst('MODULE').WithdrawSec,
                $getConst('ACTION').WithdrawSec.Create
              )
            "
          >
            <b-icon icon="plus-circle-outline"></b-icon>
            <span class="menu-text">Rút séc nhập quỹ</span>
          </b-button>
        </div>
        <div class="filter is-gapless columns mar-10">
          <div class="column is-7">
            <div class="columns is-gapless dropdown-search-custom">
              <b-field class="column is-6">
                <b-datepicker
                  class=""
                  :locale="locale"
                  placeholder="Từ ngày đến ngày"
                  icon-right="calendar-today"
                  :years-range="[-100, 100]"
                  trap-focus
                  v-model="selectDate"
                  @input="LoadWithdrawSec()"
                  range
                >
                </b-datepicker>
              </b-field>
              <b-field class="column is-3">
                <v-select
                  ref="modelBank"
                  placeholder="Ngân hàng"
                  class="style-chooser"
                  label="Value"
                  v-model="filter.bankCode"
                  :options="listBank"
                  :reduce="(option) => option.Code"
                  @input="LoadWithdrawSec"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
              <b-field class="column is-2">
                <b-dropdown
                  v-model="filter.status"
                  multiple
                  aria-role="list"
                  @input="changeStatus"
                >
                  <template #trigger>
                    <b-button icon-right="menu-down">
                      Trạng thái đã chọn ({{ filter.status.length }})
                    </b-button>
                  </template>

                  <b-dropdown-item
                    v-for="item in listStatus"
                    :value="item.Code"
                    :key="item.Code"
                    aria-role="listitem"
                  >
                    <span>{{ item.Name }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-field>
            </div>
          </div>
        </div>
        <div>
          <PagingItem :filter="filter" :total="total" />
          <div class="header-table">
            <div class="order">#</div>
            <div class="col">
              <div class="columns">
                <div class="column is-1 text-align">Ngày</div>
                <div class="column is-2 custom-w-12">Nhân viên</div>
                <div class="column is-1 text-align">Số tiền</div>
                <div class="column is-2">Hình thức</div>
                <div class="column is-1">TT Sec</div>
                <div class="column is-2">Ghi chú</div>
                <div class="column is-1 custom-w-10">Trạng thái</div>
                <div class="column is-2 custom-w-10"></div>
              </div>
            </div>
          </div>
          <div class="main-table tbl-bank-transfer-list">
            <div
              class="item-table"
              v-for="(item, idx) in ListWithdrawSec"
              :key="item.Id"
            >
              <WithdrawSecItem
                :idx="idx"
                :data="item"
                :offset="filter.offset"
                :Empoyee="Employee"
                @GetDetail="GetDetail"
                @CancelWithdrawSec="CancelWithdrawSec"
                @ConfirmWithdrawSec="ConfirmWithdrawSec"
                @Additional="Additional"
              />
            </div>
          </div>
          <PagingItem :filter="filter" :total="total" />
        </div>
      </div>
      <WithdrawSecAddPopup
        ref="WithdrawSecItemPop"
        :listBank="listBank"
        @AddSuccess="LoadWithdrawSec()"
      />
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>
<script>
import WithdrawSecAddPopup from "@/modules/withdrawsecs/components/WithdrawSecAddPopup.vue";
import WithdrawSecItem from "@/modules/withdrawsecs/components/WithdrawSecItem.vue";
import WithdrawSecServices from "@/modules/withdrawsecs/services/WithdrawSecServices.vue";
import ConvertToDateTime from "@/helpers/ConvertDatetime.vue";
import store from "@/store";
import Enum from "@/helpers/Enum.vue";
//import PopupEditImage from "@/modules/fundtransfers/components/PopupEditImage.vue";
import UploadAWS from "@/helpers/UploadAWS.vue";
import moment from "moment";
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import WithdrawSecCancelPopup from "@/modules/withdrawsecs/components/WithdrawSecCancelPopup.vue";
import WithdrawSecConfirmPopup from "@/modules/withdrawsecs/components/WithdrawSecConfirmPopup.vue";
import PopupEditImage from "@/modules/withdrawsecs/components/WithdrawSecEditImage.vue";
import ConstPlugin from "@/helpers/ConstPlugin.vue";
import UploadStatic from "@/helpers/UploadStatic.vue";
var amzUrl = process.env.VUE_APP_STATIC_DOMAIN;

export default {
  components: {
    WithdrawSecItem,
    WithdrawSecAddPopup,
  },
  data() {
    return {
      listShop: [],
      listBank: [],
      listMethod: [],
      listStatus: [],
      lstMethodChild: [],
      locale: "vi-ve",
      total: 0,
      ListWithdrawSec: [],
      isShowPopup: false,
      fromDate: null,
      toDate: null,
      selectDate: [],
      filter: {
        offset: 0,
        limit: 10,
        search: null,
        fromDate: null,
        toDate: null,
        status: ["01", "02", "03"],
        bankCode: null,
      },
      position: null,
      isLoad: false,
      ListImage: [],
      files: [],
      SMData: {
        IdFund: null,
        ListImage: [],
      },
      isLoading: false,
      lstbank: [],
      Employee: null,
    };
  },
  mounted() {
    var _self = this;
     _self.checkURL();
    //var res = store.state.user.PositionOrganization[0].Position;
    _self.Employee = store.state.user.Profile.EmployeeCode;
    _self.GetListStatusFund();
    _self.LoadWithdrawSec();
    _self.GetListBank();
  },
  methods: {
    pageChanged() {
      var _self = this;
      _self.LoadWithdrawSec();
      _self.$router.push({
        name: "ListWithdrawSec",
        query: {
          Offset: _self.filter.offset,
          Limit: _self.filter.limit,
          Search: _self.filter.search,
        },
      });
    },
    async LoadWithdrawSec() {
      var _self = this;
      _self.isLoading = true;
      if (_self.selectDate[0] != null) {
        _self.filter.fromDate = ConvertToDateTime.ConvertDatetimeToUnix(
          _self.selectDate[0]
        );
        _self.filter.toDate = ConvertToDateTime.ConvertDatetimeToUnix(
          _self.selectDate[1]
        );
      }
      var result = await WithdrawSecServices.List_PostWithdrawSec(_self.filter);
      _self.ListWithdrawSec = result.Data;
      _self.total = result.Total;
      _self.isLoading = false;
    },
    async GetListStatusFund() {
      var _self = this;
      var result = await FundTransferService.Get_ListStatusFund();
      _self.listStatus = result.filter(
        (x) => x.Code != ConstPlugin.FUNDSTATUS.Fail
      );
    },
    async deleteFile(file) {
      var _self = this;
      _self.$buefy.dialog.confirm({
        title: "Xóa ảnh",
        message: Enum.MESSAGE.ConfirmDeleteImage + file.FileName + "</b>",
        confirmText: "Đồng ý",
        cancelText: "Hủy",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          _self.isLoading = true;
          var currentItem = _self.ListImage.filter(
            (x) => x.Path == file.Path
          )[0];
          var index = _self.ListImage.indexOf(currentItem);
          if (index > -1) {
            await UploadAWS.deleteFile(file.Path);
            _self.ListImage.splice(index, 1);
            _self.SMData.ListImage = _self.ListImage;
          }
          _self.isLoading = false;
        },
      });
    },
    async uploadFiles(files) {
      let _self = this;
      _self.files = files;
      _self.isLoading = true;
      for (let i = 0; i < _self.files.length; i++) {
        let item = _self.files[i];
        let now = moment();
        let folder = "Images/Withdraw";
        let pathRoot = `${now.format("YYYY")}/${now.format("MM")}/${folder}`;
        let fileName = `${now.format("YYYYMMDD")}_${now.format(
          "HHmmss"
        )}_${now.format("X")}_${Math.floor(Math.random() * 0x10000).toString(
          16
        )}`;
        let pathFull = `${pathRoot}/${fileName}.${item.name.slice(
          item.name.lastIndexOf(".") + 1
        )}`;

        await UploadStatic.PutFileS3(
          pathFull,
          item,
          fileName,
        );

        // if (result) {
          let file = {
            Id: 0,
            Path: amzUrl + pathFull,
            FileName: fileName,
          };
          _self.ListImage.push(file);
          _self.SMData.ListImage = null;
          _self.SMData.ListImage = _self.ListImage;
        // }
      }
      _self.files = [];
      _self.isLoading = false;
    },
    ShowAddForm() {
      var _self = this;
      _self.$refs.WithdrawSecItemPop.SetActive(true);
    },
    changeStatus() {
      this.LoadWithdrawSec();
    },
    GetDetail() {},
    async GetListBank() {
      var _self = this;
      var result = await FundTransferService.Get_ListBank();
      _self.listBank = result.Data;
    },
    async CancelWithdrawSec(item) {
      var _self = this;
      _self.$buefy.modal.open({
        component: WithdrawSecCancelPopup,
        hasModalCard: true,
        trapFocus: true,
        props: {
          item: item,
        },
        events: {
          loadData: () => _self.LoadWithdrawSec(),
        },
      });
    },
    async ConfirmWithdrawSec(item) {
      var _self = this;
      _self.$buefy.modal.open({
        component: WithdrawSecConfirmPopup,
        hasModalCard: true,
        trapFocus: true,
        props: {
          item: item,
        },
        events: {
          loadData: () => _self.LoadWithdrawSec(),
        },
      });
    },
    async Additional(item) {
      var result = await WithdrawSecServices.List_PostListImage(item);
      var _self = this;
      _self.SMData.WithdrawSecId = item;
      _self.ListImage = null;
      _self.ListImage = result;
      _self.SMData.ListImage = result;
      _self.$buefy.modal.open({
        component: PopupEditImage,
        hasModalCard: true,
        trapFocus: true,
        props: {
          ListImage: _self.ListImage,
          SMData: _self.SMData,
        },
        events: {
          uploadFile: _self.uploadFiles,
          deleteFile: _self.deleteFile,
        },
      });
    },
  },
};
</script>

<style lang="scss">
</style>
