<script>
import Repository from "@/repositories/Repository.vue";
//import RepositoryMasterData from "@/repositories/Masterdata/Repository.vue";
//import RepositoryMifos from "@/repositories/Mifos/Repository.vue";
import Enum from "@/helpers/Enum.vue";
const List_PostWithdrawSec = async(item)=>{
    return await Repository.post(Enum.API.WithdrawSec.List,item);
};
const Create_PostWithdrawSec = async(item)=>{
    return await Repository.post(Enum.API.WithdrawSec.Create,item);
}
const Update_PostWithdrawSec=async(item)=>{
    return await Repository.post(Enum.API.WithdrawSec.Update,item);
}
const Update_PostUpdateImage=async(item)=>{
    return await Repository.post(Enum.API.WithdrawSec.UpdateImage,item);
}
const List_PostListImage=async(WithdrawSecId)=>{
    return await Repository.get(Enum.API.WithdrawSec.ListImage+`?WithdrawSecId=${WithdrawSecId}`);
}
export default {
List_PostWithdrawSec,
Create_PostWithdrawSec,
Update_PostWithdrawSec,
Update_PostUpdateImage,
List_PostListImage
};
</script>
